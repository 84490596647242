<template>
  <div class="content">
    <hcc-table
      :title="$t('configuration.labels.name')"
      :actualPage="actualPage"
      :pagination="rows.length > 6"
      :columns="columns"
      :rows="rows"
      :rowsPerPage="6"
      @edit="openEditModal"
      @delete="openDelete"
      @add="openAddModal"
      @pageChanged="pageChange"
      :overrideActions="hasActions"
    />
    <transition name="fade">
      <hcc-modal
        name="label-modal"
        :title="$t('configuration.labels.name')"
        @confirm="saveEdit"
        @cancel="cancel"
        :clickToClose="false"
        :confirmDisableButton="this.label.name === ''"
      >
        <div class="form-modal">
          <hcc-input
            class="modal-input"
            v-focuseModal
            :label="$t('configuration.labels.title')"
            v-model.trim="label.name"
            @keyup.enter="
              saveEdit();
              closeModal();
            "
          />
        </div>
      </hcc-modal>
    </transition>
    <transition name="fade">
      <hcc-confirmation />
    </transition>
    <transition name="fade">
      <error-modal :message="errorMessage" @error="handleErrorConfirm" />
    </transition>
  </div>
</template>

<script>
import EventBus from '@/eventBus';
import {
  UPDATE_CAMPAIGN,
  UPDATE_CAMPAIGN_ERROR,
} from '@/eventTypes';
import ErrorHandler from '@/utils/error.handler';
import { clearString } from '@/utils/helper';

export default {
  props: {
    campaign: {
      required: true,
    },
  },
  components: {
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    ErrorModal: () => import('@/components/ErrorModal.vue'),
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
  },
  data() {
    return {
      errorMessage: '',
      labelsList: [],
      labels: [],
      label: { name: '' },
      actualPage: 1,
    };
  },
  watch: {
    campaign(newVal) {
      if (newVal != null && newVal.lenght > 0) {
        this.labels = newVal.labels;
        this.updateLabelsList();
      }
    },
  },
  created() {
    EventBus.$on(UPDATE_CAMPAIGN_ERROR, (err) => {
      ErrorHandler.logErrors(err);
    });
  },
  mounted() {
    this.labels = this.campaign.labels;
    this.updateLabelsList();
    this.$emit('changed', false);
  },
  destroyed() {
    EventBus.$off(UPDATE_CAMPAIGN_ERROR);
  },
  computed: {
    columns() {
      return [{
        label: this.$t('common.name'),
        field: 'name',
      }];
    },
    rows() {
      const defaultRows = [{
        name: 'Nombre',
        title: 'Nombre',
        index: 0,
        id: this.randomID(),
        default: true,
      },
      {
        name: 'Dirección',
        title: 'Direccion',
        index: 1,
        id: this.randomID(),
        default: true,
      },
      {
        name: 'Teléfono',
        title: 'Telefono',
        index: 2,
        id: this.randomID(),
        default: true,
      },
      {
        name: 'Celular',
        title: 'Celular',
        index: 3,
        id: this.randomID(),
        default: true,
      },
      {
        name: 'Otro',
        title: 'Otro',
        index: 4,
        id: this.randomID(),
        default: true,
      },
      ];
      this.labelsList.forEach((label) => {
        defaultRows.push(label);
      });

      return defaultRows;
    },
  },
  methods: {
    updateLabelsList() {
      this.cancel();
      const list = [];
      this.labels.forEach((lab, i) => {
        list.push({
          name: lab,
          title: lab,
          index: i + 5,
          id: this.randomID(),
          default: false,
        });
      });

      this.labelsList = list;
    },
    openAddModal() {
      this.$modal.show('label-modal');
    },
    handleErrorConfirm() {
      this.cancel();
    },
    cancel() {
      this.label = { name: '' };
      this.errorMessage = '';
    },
    openDelete(props) {
      this.$modal.show('confirmation', {
        title: `Delete "${props.name}"`,
        description: this.$t('configuration.confirmation'),
        variant: 'error',
        confirm: () => this.saveDelete(props),
      });
    },
    saveDelete(props) {
      this.labelsList.splice(props.index - 5, 1);
      const data = this.labelsList.filter(lab => !lab.default).map(l => l.name);
      this.save(data);
    },
    openEditModal(props) {
      this.label = props;
      this.$modal.show('label-modal');
    },
    saveEdit() {
      const { index } = this.label;
      if (this.rows.some(item => clearString(item.name).toLowerCase()
      === clearString(this.label.name).toLowerCase())) {
        this.$toasted.global.error(this.$t('configuration.error-exists'));
      } else if (!this.validateLabel(this.label.name)) {
        const dataToSave = this.labelsList.filter(lab => !lab.default).map(data => data.name);
        if (index != null) {
          dataToSave[index - 5] = this.label.name;
        } else {
          dataToSave.push(this.label.name);
        }
        this.save(dataToSave);
      } else {
        this.errorMessage = this.label.name === '' ? this.$t('configuration.error-empty')
          : this.$t('configuration.error-exists');
        this.label.name = '';
        this.$toasted.global.error(this.errorMessage);
      }
    },
    save(labels) {
      const data = {
        id: this.campaign.id,
        labels,
      };
      EventBus.$emit(UPDATE_CAMPAIGN, { data, tab: 'Labels' });
      this.newLabels = [];
    },
    validateLabel(name) {
      return (name === '') || this.labelsList.some(l => l.name === name);
    },
    hasActions(row) {
      return !row.default;
    },
    closeModal() {
      this.$modal.hide('label-modal');
    },
    pageChange(page) {
      this.actualPage = page;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~styles/settings/shared/_modal.scss";
</style>
